<script setup>

import $ from 'jquery';
import {LazyImg, Waterfall} from "vue-waterfall-plugin-next";

// 判断元素是否在屏幕中央
function isElementInViewport(el, tolerance) {
  if (!el) return false; // 确保 el 存在
  const rect = el.getBoundingClientRect();
  const windowHeight = window.innerHeight || document.documentElement.clientHeight;

  // 计算视口竖直中央的位置
  const viewportCenterY = windowHeight / 2;


  // 判断元素的顶部是否在视口竖直中央范围内
  return rect.top >= (viewportCenterY - tolerance) && rect.top <= (viewportCenterY + tolerance);
}

$(window).on('scroll load', function () {
  const $myFadeIn = $('.my-fadeIn');

  if ($myFadeIn.length) {
    $myFadeIn.each(function () {
      const $this = $(this); // 当前元素
      // 检查当前元素是否在视口中
      if (isElementInViewport(this, 400)) {
        $this.addClass('animated fadeIn');
      }
    });
  }

});

// 触发一次以确保在加载时检查
$(window).trigger('scroll');
//https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2023.10.22.webp
// 直播列表
const liveBroadCastList = [
  {
    link: 'https://www.bilibili.com/video/BV1SCkRYnEFm/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.12.24.jpg',
    date: '2024.12.24',
    title: '平安夜快乐！🎄'
  },
  {
    link: 'https://www.bilibili.com/video/BV18LzHYSEm5/?spm_id_from=333.337.search-card.all.click',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image%2F2024.11.30.jpg',
    date: '2024.11.30',
    title: 'Long time no see😭'
  },
  {
    link: 'https://www.bilibili.com/video/BV1JqmDYqEe3?spm_id_from=333.788.player.switch&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a&p=2',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image%2F2024.11.14.jpg',
    date: '2024.11.14',
    title: '今天也是下班早的一天耶✌️'
  },
  {
    link: 'https://www.bilibili.com/video/BV1XEDrYzEhB/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.11.09.jpg',
    date: '2024.11.09',
    title: '失踪人口回归🎉'
  },
  {
    link: 'https://www.bilibili.com/video/BV1YjCDYjE5j/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.10.18.webp',
    date: '2024.10.18',
    title: '一周年快乐！🎉'
  },
  {
    link: 'https://www.bilibili.com/video/BV1X92yYqEHA/?spm_id_from=333.999.0.0',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.10.07.webp',
    date: '2024.10.07',
    title: '四川口音瑶限时登场🌶️'
  },
  {
    link: 'https://www.bilibili.com/video/BV1f219YgE9b/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.10.05.webp',
    date: '2024.10.05',
    title: '嘟嘟嘟哒哒哒终于放假啦！🥳'
  },
  {
    link: 'https://www.bilibili.com/video/BV12Xx7eDEHR/?spm_id_from=333.337.search-card.all.click',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.09.26.webp',
    date: '2024.09.26',
    title: 'v我50之和瑶瑶一起疯狂星期四！🍗🍺'
  },
  {
    link: 'https://www.bilibili.com/video/BV17ntvesEnt/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.09.17.(2).webp',
    date: '2024.09.17',
    title: '和瑶瑶一起过中秋🥮（下）'
  },
  {
    link: 'https://www.bilibili.com/video/BV1p1tYebEDV/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.09.17.(1).webp',
    date: '2024.09.17',
    title: '和瑶瑶一起过中秋🥮（上）'
  },
  {
    link: 'https://www.bilibili.com/video/BV1wA4mehEdJ/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.09.15.webp',
    date: '2024.09.15',
    title: '难得下班早的一天💨'
  },
  {
    link: 'https://www.bilibili.com/video/BV1X6HseMEk8/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.09.03.webp',
    date: '2024.09.03',
    title: '今天是机场吃播瑶😋'
  },
  {
    link: 'https://www.bilibili.com/video/BV15aWveGEJu/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.08.25.webp',
    date: '2024.08.25',
    title: 'Happy birthday!🎂🎈🎁🎊'
  },
  {
    link: 'https://www.bilibili.com/video/BV16gWMerEdv/?spm_id_from=333.337.search-card.all.click',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.08.21.webp',
    date: '2024.08.21',
    title: '黑神话·悟空—重生之我是天命瑶🐵'
  },
  {
    link: 'https://www.bilibili.com/video/BV1qYYJeZEF2/?spm_id_from=333.337.search-card.all.click',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.08.10.webp',
    date: '2024.08.10',
    title: '陪瑶瑶过的第一个七夕💌'
  },
  {
    link: 'https://www.bilibili.com/video/BV1niige1EAi/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.08.02.webp',
    date: '2024.08.02',
    title: '早六晚九的疲惫瑶😴'
  },
  {
    link: 'https://www.bilibili.com/video/BV1cuvve7EMD/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.08.01.webp',
    date: '2024.08.01',
    title: '瑶瑶染发记（第三部）👱‍♀️'
  },
  {
    link: 'https://www.bilibili.com/video/BV1tw4m1k7Gk/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.07.20.webp',
    date: '2024.07.20',
    title: '喜欢和爱是需要表达的🩷'
  },
  {
    link: 'https://www.bilibili.com/video/BV1Cx4y147Ps/?spm_id_from=333.337.search-card.all.click',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.07.01.webp',
    date: '2024.07.01',
    title: '梦回杭州漫展👗'
  },
  {
    link: 'https://www.bilibili.com/video/BV1Vm421V7Zd/?spm_id_from=333.337.search-card.all.click',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.06.14.webp',
    date: '2024.06.14',
    title: '我不想毕业之对宿舍恋恋不舍瑶🎓'
  },
  {
    link: 'https://www.bilibili.com/video/BV1GT421e7Bs/?spm_id_from=333.337.search-card.all.click',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.06.10.webp',
    date: '2024.06.10',
    title: '呜呼呜呼！珍珍首次登场！🥳'
  },
  {
    link: 'https://www.bilibili.com/video/BV1Pi421S72m/?spm_id_from=333.337.search-card.all.click',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.05.21.webp',
    date: '2024.05.21',
    title: '魔都矩阵闯关瑶🥇'
  },
  {
    link: 'https://www.bilibili.com/video/BV1eH4y1u7ko/?spm_id_from=333.337.search-card.all.click',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.05.20(2).webp',
    date: '2024.05.20',
    title: '和瑶瑶一起过520🩷'
  },
  {
    link: 'https://www.bilibili.com/video/BV1Rs421w7sy/?spm_id_from=333.337.search-card.all.click',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.05.20(1).webp',
    date: '2024.05.20',
    title: '和瑶瑶一起去哔哩哔哩扫楼🥳'
  },
  {
    link: 'https://www.bilibili.com/video/BV1kf421U7uU/?spm_id_from=333.337.search-card.all.click',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.05.03.webp',
    date: '2024.05.03',
    title: '和瑶瑶一起去看音乐节🎵'
  },
  {
    link: 'https://www.bilibili.com/video/BV1fr421u7mo/?spm_id_from=333.337.search-card.all.click',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.05.02.webp',
    date: '2024.05.02',
    title: '和簪花瑶一起夜游闽越水镇🌸🚣'
  },
  {
    link: 'https://www.bilibili.com/video/BV1hw4m1C767/?spm_id_from=333.337.search-card.all.click',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.04.30.webp',
    date: '2024.04.30',
    title: '今天是电影节下班后的礼服瑶️💃'
  },
  {
    link: 'https://www.bilibili.com/video/BV1PJ4m1n7HU/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.04.24.webp',
    date: '2024.04.24',
    title: '和瑶瑶一起打气球、套小玩具🎈🧸'
  },
  {
    link: 'https://www.bilibili.com/video/BV1bi42127Wh/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.04.18.webp',
    date: '2024.04.18',
    title: '清晨偷袭之和瑶瑶一起吃早餐🥣'
  },
  {
    link: 'https://www.bilibili.com/video/BV1dm411k7c1/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.03.31.webp',
    date: '2024.03.31',
    title: '和瑶瑶一起去逛街吃美食🍟🍔'
  },
  {
    link: 'https://www.bilibili.com/video/BV1Gz421o7hN/?spm_id_from=333.337.search-card.all.click',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.03.29.webp',
    date: '2024.03.29',
    title: '瑶瑶染发记（第二部）👱‍♀'
  },
  {
    link: 'https://www.bilibili.com/video/BV1dy421z77C/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.02.24.webp',
    date: '2024.02.24',
    title: '和瑶瑶一起欢度元宵🎆'
  },
  {
    link: 'https://www.bilibili.com/video/BV1yZ42117k2/?spm_id_from=333.337.search-card.all.click',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.02.20.webp',
    date: '2024.02.20',
    title: '擅自开播的呆呆瑶首次登场😳'
  },
  {
    link: 'https://www.bilibili.com/video/BV1j4421F7cm/?spm_id_from=333.337.search-card.all.click',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.02.14.webp',
    date: '2024.02.14',
    title: '陪瑶瑶过的第一个情人节❤️'
  },
  {
    link: 'https://www.bilibili.com/video/BV1Ju4m1K7um/?spm_id_from=333.337.search-card.all.click',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.02.09.webp',
    date: '2024.02.09',
    title: '除夕夜之和瑶瑶一起唱歌放烟花！🎤🎇'
  },
  {
    link: 'https://www.bilibili.com/video/BV1f4421A7A6/?spm_id_from=333.337.search-card.all.click',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.02.08.webp',
    date: '2024.02.08',
    title: '元...元...元梦之星启动！🎮'
  },
  {
    link: 'https://www.bilibili.com/video/BV1Rv4211734/?spm_id_from=333.337.search-card.all.click',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.02.05.webp',
    date: '2024.02.05',
    title: '和瑶瑶一起秋秋飞车！🚗'
  },
  {
    link: 'https://www.bilibili.com/video/BV1GK411Y7cJ/?spm_id_from=333.337.search-card.all.click',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.01.23.webp',
    date: '2024.01.23',
    title: '瑶瑶染发记（第一部）👱‍♀️'
  },
  {
    link: 'https://www.bilibili.com/video/BV1y5411v7sE/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2024.01.11.webp',
    date: '2024.01.11',
    title: '偷袭之凌晨三点不睡觉准有好事🕒'
  },
  {
    link: 'https://www.bilibili.com/video/BV1Tw411G7sR/?spm_id_from=333.337.search-card.all.click',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2023.12.25.webp',
    date: '2023.12.25',
    title: '和瑶瑶一起畅玩 gogo 新天地🎄'
  },
  {
    link: 'https://www.bilibili.com/video/BV1ye411C7V9/?spm_id_from=333.337.search-card.all.click',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2023.12.10.webp',
    date: '2023.12.10',
    title: '古希腊之掌管答案之书的瑶👸'
  },
  {
    link: 'https://www.bilibili.com/video/BV1XN4y1176Z/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2023.11.26.webp',
    date: '2023.11.26',
    title: '率土之滨之钟极大招！🎮'
  },
  {
    link: 'https://www.bilibili.com/video/BV1Gw411K7sD/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2023.11.18.webp',
    date: '2023.11.18',
    title: '英雄联盟手游“团魂之夜”之吃播瑶🍽️'
  },
  {
    link: 'https://www.bilibili.com/video/BV1Z84y1Q7g5/?spm_id_from=333.337.search-card.all.click',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2023.11.09.webp',
    date: '2023.11.09',
    title: '郑梓妍经典动作再现🫰'
  },
  {
    link: 'https://www.bilibili.com/video/BV1fG411X7SY/?spm_id_from=333.337.search-card.all.click',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2023.11.05.webp',
    date: '2023.11.05',
    title: '和瑶瑶一起在天台吹风🏡'
  },
  {
    link: 'https://www.bilibili.com/video/BV1Wc411d71h/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2023.10.30.webp',
    date: '2023.10.30',
    title: '世界上又多了一名百万粉丝博主🎉'
  },
  {
    link: 'https://www.bilibili.com/video/BV1DN411x7EW/?spm_id_from=333.337.search-card.all.click',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2023.10.23.webp',
    date: '2023.10.23',
    title: '日不落live封神现场！🎤'
  },
  {
    link: 'https://www.bilibili.com/video/BV1Xe411R7Ys/?spm_id_from=333.337.search-card.all.click&vd_source=1c3f874ee4a7ebc47cd0b83d282c3d8a',
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/2023.10.22.webp',
    date: '2023.10.22',
    title: '初出茅庐 请多多关照！🙈'
  }
]

const breakpoints = {
  1200: { //当屏幕宽度小于等于1200
    rowPerView: 4,
  },
  800: { //当屏幕宽度小于等于800
    rowPerView: 3,
  },
  500: { //当屏幕宽度小于等于500
    rowPerView: 2,
  }
}

</script>

<template>
  <div class="whole">
    <audio loop autoplay>
      <source src="../assets/music/兜兜转转-小京东.mp3">
    </audio>
    <div style="height: 85vh; display: flex; align-items: center">
      <div class="welcome-title animated fadeInUp">
        Welcome to LOVE.ZCY.FOREVER
      </div>
    </div>
    <div style="margin-top: 7rem">
      <div style="color: white; font-size: 15px; font-weight: bolder">人物简介</div>
      <div style="color: white; font-size: 10px; font-weight: lighter">ZCY INFO</div>
      <div class="my-fadeIn" style="margin-top: 1rem">
        <div style="display: flex; justify-content: center">
          <vs-card style="height: 180px" type="2">
            <template #title>
              <h3>钟晨瑶</h3>
            </template>
            <template #img>
              <img style="width: 300px"
                   src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/zcy2.webp" alt="">
            </template>
            <template #text>
              <p>
                《完蛋！我被美女包围了》真人互动影游——“午夜精灵”郑梓妍
              </p>
            </template>
          </vs-card>
        </div>
        <div class="personal-info-area" style="width: 100%;">
          <div class="personal-info-area-text">
            <div class="personal-info-line">
              1998年，钟晨瑶出生于福建省三明市，并在那里读书、长大。童年时她经常参加学校或县城里的晚会、舞蹈演出等，初中时学习过一年美声。高中时期一次偶然的机会，她了解到了演员希斯莱杰的故事，心里埋下了想当演员的种子。在华东政法大学就读两年后，她毅然决定退学重新参加高考，被中央戏剧学院录取。
            </div>
            <div class="personal-info-line">
              2020年1月21日，主演的贺岁微电影《遇见你》发布，这是张艺谋导演团队竖屏美学系列微电影的第一辑。2022年3月3日，与樊治欣、乔靖雯联袂主演的古装爱情轻喜剧《谢公子的酒》在腾讯视频独播，在剧中饰演男主人公谢退思的表妹、酒肆里的员工小棠。同年8月，她和同学一起代表中戏参加“爱·庐山”影视短片季，参赛作品《淙淙》获得该届影视短片季最佳影片、2022年SHISW短片奖十一月最佳故事短片荣誉提名。
            </div>
          </div>
        </div>
      </div>
      <div class="my-fadeIn">
        <div style="margin-top: 1rem; display: flex; justify-content: center">
          <vs-card style="width: 300px" type="2">
            <template #title>
              <h3>钟晨瑶</h3>
            </template>
            <template #img>
              <img style="height: 180px"
                   src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/zcy1.webp" alt="">
            </template>
            <template #text>
              <p>
                《热搜》北京首映礼——在剧中饰演实习生“温妮”
              </p>
            </template>
          </vs-card>
        </div>
        <div class="personal-info-area "
             style="width: 100%;">
          <div class="personal-info-area-text">
            <div class="personal-info-line">
              2023年7月31日，她主演的剧情短片《离校日》发布，她在片中饰演男主人公王杰暗恋了两年的同班同学叶豫文。同年10月12日，与梁戟、刘添祺领衔主演的剧情电影《二次呼吸》在第七届平遥国际电影展首映。
            </div>
            <div class="personal-info-line">
              同年10月18日，与王姿允、邹佳佳等人领衔主演的真人恋爱影视游戏《完蛋！我被美女包围了！》上线，在游戏中饰演午夜精灵郑梓妍。11月30日，与周冬雨、宋洋等人共同出演的剧情电影《热搜》上映，在片中饰演实习生温妮。
            </div>
          </div>
        </div>
      </div>
      <div class="my-fadeIn">
        <div style=" margin-top: 1rem; display: flex; justify-content: center">
          <vs-card style="width: 180px" type="2">
            <template #title>
              <h3>钟晨瑶</h3>
            </template>
            <template #img>
              <img style="height: 180px"
                   src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/zcy3.webp" alt="">
            </template>
            <template #text>
              <p>
                芒果TV重点项目、短剧《暗夜浪漫》——设计师许望
              </p>
            </template>
          </vs-card>
        </div>
        <div class="personal-info-area"
             style="width:100%;">
          <div class="personal-info-area-text">
            <div class="personal-info-line animate__backInLeft">
              2024年3月21-24日，在中国国家话剧院原创话剧《蓟州疑云》中反串饰演小铺兵罗小本。该剧获得了北京文化艺术基金的年度资助，作为开幕大戏为中国国家话剧院和西城区人民政府联合主办的第九届中国原创话剧邀请展拉开帷幕。
            </div>
            <div class="personal-info-line">
              2024年3月30日，领衔主演的网剧《咸鱼反击》正式开机，在剧中饰演大姐大郭苗。该剧于2024年4月24日顺利杀青。2024年8月22日，主演的短剧《暗夜浪漫》在芒果TV正式上线，在剧中一人分饰两角，饰演孪生姐妹许希/许望。
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 7rem">
      <div style="color: white; font-size: 20px; font-weight: bolder">线下动态</div>
      <div style="color: white; font-size: 13px; font-weight: lighter; margin-bottom: 1rem">ZCY OFFLINE EVENTS</div>
      <div class="my-fadeIn" style="width: 95%; margin-left: auto; margin-right: auto">
        <el-scrollbar>
          <div style="display: flex; height: 240px;" class="my-fadeInRight animated fadeIn">
            <img class="carousel-img"
                 src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/钟晨瑶北京路演.webp" alt="">
            <img class="carousel-img"
                 src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/钟晨瑶上海路演.webp" alt="">
            <img class="carousel-img"
                 src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/钟晨瑶杭州路演.webp" alt="">
            <img class="carousel-img"
                 src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/钟晨瑶广州路演.webp" alt="">
            <img class="carousel-img"
                 src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/钟晨瑶重庆路演.webp" alt="">
            <img class="carousel-img"
                 src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/钟晨瑶郑州路演.webp" alt="">
            <img class="carousel-img"
                 src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/钟晨瑶成都cf.webp" alt="">
            <img class="carousel-img"
                 src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/钟晨瑶厦门电影节.webp" alt="">
            <img class="carousel-img"
                 src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/钟晨瑶广州gogo.webp" alt="">
            <img class="carousel-img"
                 src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/钟晨瑶广州漫展.webp" alt="">
            <img class="carousel-img"
                 src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/钟晨瑶北京话剧.webp" alt="">
            <img class="carousel-img"
                 src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/钟晨瑶杭州漫展.webp" alt="">
            <img class="carousel-img"
                 src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/钟晨瑶上海520.webp" alt="">
            <img class="carousel-img"
                 src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/钟晨瑶沈阳漫展.webp" alt="">
          </div>
        </el-scrollbar>
      </div>
      <div class="my-fadeIn" style="margin-bottom: 3rem; margin-top: 1rem; padding: 1rem">
        <el-scrollbar style="height: 120px;">
          <n-timeline horizontal>
            <n-timeline-item>
              <div class="offline-events-timeline-item">
                <div class="offline-events-timeline-item-content">
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/shijian.webp"
                         alt="">
                    <div class="offline-events-timeline-item-content-text">2023.11.25</div>
                  </div>
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/didian.webp"
                         alt="">
                    <div class="offline-events-timeline-item-content-text">北京</div>
                  </div>
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/event.webp" alt="">
                    <div class="offline-events-timeline-item-content-text">《热搜》首映礼</div>
                  </div>
                </div>
              </div>
            </n-timeline-item>
            <n-timeline-item>
              <div class="offline-events-timeline-item">
                <div class="offline-events-timeline-item-content">
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/shijian.webp"
                         alt="">
                    <div class="offline-events-timeline-item-content-text">2023.11.27</div>
                  </div>
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/didian.webp"
                         alt="">
                    <div class="offline-events-timeline-item-content-text">上海</div>
                  </div>
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/event.webp" alt="">
                    <div class="offline-events-timeline-item-content-text">《热搜》路演</div>
                  </div>
                </div>
              </div>
            </n-timeline-item>
            <n-timeline-item>
              <div class="offline-events-timeline-item">
                <div class="offline-events-timeline-item-content">
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/shijian.webp"
                         alt="">
                    <div class="offline-events-timeline-item-content-text">2023.11.28</div>
                  </div>
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/didian.webp"
                         alt="">
                    <div class="offline-events-timeline-item-content-text">杭州</div>
                  </div>
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/event.webp" alt="">
                    <div class="offline-events-timeline-item-content-text">《热搜》路演</div>
                  </div>
                </div>
              </div>
            </n-timeline-item>
            <n-timeline-item color="green">
              <div class="offline-events-timeline-item">
                <div class="offline-events-timeline-item-content">
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/shijian.webp"
                         alt="">
                    <div class="offline-events-timeline-item-content-text">2023.11.29</div>
                  </div>
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/didian.webp"
                         alt="">
                    <div class="offline-events-timeline-item-content-text">广州</div>
                  </div>
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/event.webp" alt="">
                    <div class="offline-events-timeline-item-content-text">《热搜》路演</div>
                  </div>
                </div>
              </div>
            </n-timeline-item>
            <n-timeline-item>
              <div class="offline-events-timeline-item">
                <div class="offline-events-timeline-item-content">
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/shijian.webp"
                         alt="">
                    <div class="offline-events-timeline-item-content-text">2023.11.30</div>
                  </div>
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/didian.webp"
                         alt="">
                    <div class="offline-events-timeline-item-content-text">重庆</div>
                  </div>
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/event.webp" alt="">
                    <div class="offline-events-timeline-item-content-text">《热搜》路演</div>
                  </div>
                </div>
              </div>
            </n-timeline-item>
            <n-timeline-item>
              <div class="offline-events-timeline-item">
                <div class="offline-events-timeline-item-content">
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/shijian.webp"
                         alt="">
                    <div class="offline-events-timeline-item-content-text">2023.12.01</div>
                  </div>
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/didian.webp"
                         alt="">
                    <div class="offline-events-timeline-item-content-text">郑州</div>
                  </div>
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/event.webp" alt="">
                    <div class="offline-events-timeline-item-content-text">《热搜》路演</div>
                  </div>
                </div>
              </div>
            </n-timeline-item>
            <n-timeline-item>
              <div class="offline-events-timeline-item">
                <div class="offline-events-timeline-item-content">
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/shijian.webp"
                         alt="">
                    <div class="offline-events-timeline-item-content-text">2023.12.07</div>
                  </div>
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/didian.webp"
                         alt="">
                    <div class="offline-events-timeline-item-content-text">成都</div>
                  </div>
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/event.webp" alt="">
                    <div class="offline-events-timeline-item-content-text">CNCF文殊坊助威夜</div>
                  </div>
                </div>
              </div>
            </n-timeline-item>
            <n-timeline-item>
              <div class="offline-events-timeline-item">
                <div class="offline-events-timeline-item-content">
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/shijian.webp"
                         alt="">
                    <div class="offline-events-timeline-item-content-text">2023.12.19-20</div>
                  </div>
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/didian.webp"
                         alt="">
                    <div class="offline-events-timeline-item-content-text">厦门</div>
                  </div>
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/event.webp" alt="">
                    <div class="offline-events-timeline-item-content-text">厦门.柬埔寨电影周</div>
                  </div>
                </div>
              </div>
            </n-timeline-item>
            <n-timeline-item>
              <div class="offline-events-timeline-item">
                <div class="offline-events-timeline-item-content">
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/shijian.webp"
                         alt="">
                    <div class="offline-events-timeline-item-content-text">2023.12.25</div>
                  </div>
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/didian.webp"
                         alt="">
                    <div class="offline-events-timeline-item-content-text">广州</div>
                  </div>
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/event.webp" alt="">
                    <div class="offline-events-timeline-item-content-text">腾讯游戏“转运节”</div>
                  </div>
                </div>
              </div>
            </n-timeline-item>
            <n-timeline-item>
              <div class="offline-events-timeline-item">
                <div class="offline-events-timeline-item-content">
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/shijian.webp"
                         alt="">
                    <div class="offline-events-timeline-item-content-text">2023.12.30</div>
                  </div>
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/didian.webp"
                         alt="">
                    <div class="offline-events-timeline-item-content-text">广州</div>
                  </div>
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/event.webp" alt="">
                    <div class="offline-events-timeline-item-content-text">广州萤火虫漫展</div>
                  </div>
                </div>
              </div>
            </n-timeline-item>
            <n-timeline-item color="green">
              <div class="offline-events-timeline-item">
                <div class="offline-events-timeline-item-content">
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/shijian.webp"
                         alt="">
                    <div class="offline-events-timeline-item-content-text">2024.03.21-24</div>
                  </div>
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/didian.webp"
                         alt="">
                    <div class="offline-events-timeline-item-content-text">北京</div>
                  </div>
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/event.webp" alt="">
                    <div class="offline-events-timeline-item-content-text">《蓟州疑云》话剧</div>
                  </div>
                </div>
              </div>
            </n-timeline-item>
            <n-timeline-item color="green">
              <div class="offline-events-timeline-item">
                <div class="offline-events-timeline-item-content">
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/shijian.webp"
                         alt="">
                    <div class="offline-events-timeline-item-content-text">2024.03.24</div>
                  </div>
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/didian.webp"
                         alt="">
                    <div class="offline-events-timeline-item-content-text">杭州</div>
                  </div>
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/event.webp" alt="">
                    <div class="offline-events-timeline-item-content-text">杭州AD02漫展</div>
                  </div>
                </div>
              </div>
            </n-timeline-item>
            <n-timeline-item>
              <div class="offline-events-timeline-item">
                <div class="offline-events-timeline-item-content">
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/shijian.webp"
                         alt="">
                    <div class="offline-events-timeline-item-content-text">2024.05.20</div>
                  </div>
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/didian.webp"
                         alt="">
                    <div class="offline-events-timeline-item-content-text">上海</div>
                  </div>
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/event.webp" alt="">
                    <div class="offline-events-timeline-item-content-text">哔哩哔哩 520 扫楼</div>
                  </div>
                </div>
              </div>
            </n-timeline-item>
            <n-timeline-item color="green">
              <div class="offline-events-timeline-item">
                <div class="offline-events-timeline-item-content">
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/shijian.webp"
                         alt="">
                    <div class="offline-events-timeline-item-content-text">2024.08.16</div>
                  </div>
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/didian.webp"
                         alt="">
                    <div class="offline-events-timeline-item-content-text">沈阳</div>
                  </div>
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/event.webp" alt="">
                    <div class="offline-events-timeline-item-content-text">沈阳SSCA漫展</div>
                  </div>
                </div>
              </div>
            </n-timeline-item>
            <n-timeline-item>
              <div class="offline-events-timeline-item">
                <div class="offline-events-timeline-item-content">
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/shijian.webp"
                         alt="">
                    <div class="offline-events-timeline-item-content-text">未来</div>
                  </div>
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/didian.webp"
                         alt="">
                    <div class="offline-events-timeline-item-content-text">未知</div>
                  </div>
                  <div style="display: flex">
                    <img class="offline-events-timeline-item-content-icon" src="../assets/icon/event.webp" alt="">
                    <div class="offline-events-timeline-item-content-text">未完待续</div>
                  </div>
                </div>
              </div>
            </n-timeline-item>
          </n-timeline>
        </el-scrollbar>
      </div>
    </div>
    <div style="margin-top: 7rem">
      <div style="color: white; font-size: 20px; font-weight: bolder">直播动态</div>
      <div style="color: white; font-size: 13px; font-weight: lighter">ZCY LIVE BROADCAST</div>
      <div
          style="width: 95%; margin-left: auto; margin-right: auto; margin-top: 1rem">
        <Waterfall class="animated fadeInUp" :width="180" :list="liveBroadCastList" :breakpoints="breakpoints"
                   style="background-color: rgba(235,235,235,0); border-radius: 0.5rem">
          <template #default="{ item }">
            <a :href="item.link" target="_blank">
              <div class="broadcast-item">
                <LazyImg :url="item.src" class="broadcast-item-image"/>
                <div class="broadcast-item-text">{{ item.title }}</div>
                <div class="broadcast-item-time">{{ item.date }}</div>
              </div>
            </a>
          </template>
        </Waterfall>
      </div>
    </div>
    <n-back-top :right="30" :bottom="30"/>
  </div>

</template>

<style scoped>

.whole {
  background-color: rgba(0, 0, 0, 0);
  justify-content: center;
  background-size: cover;
  background-position: center;
  //background-image: url("https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/pricing-bg-dot-shape.png");
  background-attachment: fixed;
}

.welcome-title {
  font-size: 35px;
  color: rgba(235, 235, 235, 0.6);
  font-family: Georgia, serif;
  font-style: italic;
  font-weight: 50;
  transition: font-size 0.4s ease;
  margin-left: auto;
  margin-right: auto;
}

.personal-info-line {
  text-indent: 1.8em;
  font-size: 12px;
  color: white;
  text-align: left;
  margin-top: 10px;
}

.personal-info-area {
  border-radius: 10px;
  padding: 1rem;
}

.animated.fadeIn {
  animation-duration: 4.0s; /* 设置动画持续时间为 2 秒 */
}

.offline-events-timeline-item-content-icon {
  height: 12px;
  margin: 0.1rem;
}

.offline-events-timeline-item-content-text {
  margin-left: 0.3rem;
  margin-right: 0.3rem;
  padding: 0.2rem
}

.offline-events-timeline-item-content {
  color: #ebebeb;
  font-size: 10px;
}

.broadcast-item-image {
  padding: 0.3rem;
  border-radius: 10px;
  transition: height 0.3s ease;
}

.broadcast-item {
  padding: 0.2rem;
  background-color: rgba(235, 235, 235, 0);
}

.broadcast-item-text {
  text-align: left;
  color: white;
  font-family: Georgia, serif;
  font-size: 14px;
  font-weight: 700;
  margin-left: 0.3rem;
}

.broadcast-item-time {
  padding: 0;
  color: #ebebeb;
  font-size: 12px;
  font-weight: 600;
  font-family: Georgia, serif;
  transition: font-size 0.3s ease;
  text-align: left;
  margin-left: 0.3rem;
}

</style>